import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { withPage } from "../../Page";
import Selectors from "../../Selectors";
import ActionCreator from "../../ActionCreator";
import * as Widget from "../../Components/Widget";
import ParseQuery from "../../Utils/ParseQuery";

class SocialLoginResultPage extends React.Component {
  state = {
    data: null
  };
  componentDidMount() {
    let { location, appActions, navActions } = this.props;
    let queryParams = ParseQuery(location.search);
    let data = JSON.parse(decodeURIComponent(queryParams.data));

    new Promise(resolve => setTimeout(resolve, 600))
      .then(() => this.setState({ data }))
      .then(() => {
        if (data.token) {
          window.localStorage.setItem("token", data.token);
          appActions.autoLogin().then(() => {
            let localSettingUid = appActions.getLocalSettingUid();
            if (localSettingUid) {
              navActions.push(`/redirect/?uid=${localSettingUid}`);
            } else {
              navActions.push("/me");
            }
          });
        }
      })
      .catch(err => console.warn(err));
  }

  render() {
    let { siteConfig } = this.props;

    return (
      <Spinner>
        <img src="/images/loading.png" />
        <div>Loading...</div>
      </Spinner>
    );
  }
}

const Spinner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100vw;
  height: 100vh;
  padding: 10px;

  & > img {
    max-width: 200px;
    width: 100%;
    max-height: 200px;
    height: 100%;
  }

  & > div {
    white-space: pre-wrap;
    text-align: center;
    font-size: 20px;
    animation-name: loading;
    animation-duration: 800ms;
    animation-iteration-count: infinite;

    @keyframes loading {
      from {
        color: #3f2628;
      }
      to {
        color: #7e7e7e;
      }
    }
  }
`;

export default withPage(
  connect(
    (state, ownProps) => ({
      profile: Selectors.getLoginUser(state),
      siteConfig: Selectors.getSiteConfig(state)
    }),
    ActionCreator
  )(SocialLoginResultPage)
);
